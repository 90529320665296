define("discourse/plugins/Moderatori Plugin/discourse/components/button-edit-read-restriction", ["exports", "@ember/modifier", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template", "discourse/components/glimmer-component-with-deprecated-parent-view", "discourse/helpers/concat-class", "discourse/lib/url", "discourse-common/helpers/d-icon", "discourse-common/lib/deprecated", "discourse-i18n", "truth-helpers/helpers/or", "discourse/lib/ajax", "@ember/component", "@ember/template-factory"], function (_exports, _modifier, _object, _computed, _service, _template, _glimmerComponentWithDeprecatedParentView, _concatClass, _url, _dIcon, _deprecated, _discourseI18n, _or, _ajax, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ACTION_AS_STRING_DEPRECATION_ARGS = ["DButton no longer supports @action as a string. Please refactor to use an closure action instead.", {
    id: "discourse.d-button-action-string"
  }];
  class DButton extends _glimmerComponentWithDeprecatedParentView.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "btnIcon", [(0, _computed.notEmpty)("args.icon")]))();
    #btnIcon = (() => (dt7948.i(this, "btnIcon"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "btnLink", [(0, _computed.equal)("args.display", "link")]))();
    #btnLink = (() => (dt7948.i(this, "btnLink"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "noText", [(0, _computed.empty)("computedLabel")]))();
    #noText = (() => (dt7948.i(this, "noText"), void 0))();
    constructor() {
      super(...arguments);
      if (typeof this.args.action === "string") {
        (0, _deprecated.default)(...ACTION_AS_STRING_DEPRECATION_ARGS);
      }
    }
    get forceDisabled() {
      return !!this.args.isLoading;
    }
    get isDisabled() {
      return this.forceDisabled || this.args.disabled;
    }
    get btnType() {
      if (this.args.icon) {
        return this.computedLabel ? "btn-icon-text" : "btn-icon";
      } else if (this.computedLabel) {
        return "btn-text";
      }
    }
    get computedTitle() {
      if (this.args.title) {
        return _discourseI18n.default.t(this.args.title);
      }
      return this.args.translatedTitle;
    }
    get computedLabel() {
      if (this.args.label) {
        return _discourseI18n.default.t(this.args.label);
      }
      return this.args.translatedLabel;
    }
    get computedAriaLabel() {
      if (this.args.ariaLabel) {
        return _discourseI18n.default.t(this.args.ariaLabel);
      }
      if (this.args.translatedAriaLabel) {
        return this.args.translatedAriaLabel;
      }
    }
    get computedAriaExpanded() {
      if (this.args.ariaExpanded === true) {
        return "true";
      }
      if (this.args.ariaExpanded === false) {
        return "false";
      }
    }
    keyDown(e) {
      if (this.args.onKeyDown) {
        e.stopPropagation();
        this.args.onKeyDown(e);
      } else if (e.key === "Enter") {
        this._triggerAction(e);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "keyDown", [_object.action]))();
    click(event) {
      let category_id = this.args.category_id;
      (0, _ajax.ajax)(`/category_read_restricted/${category_id}`, {}).then(uploads => location.reload());
    }
    static #_6 = (() => dt7948.n(this.prototype, "click", [_object.action]))();
    mouseDown(event) {
      if (this.args.preventFocus) {
        event.preventDefault();
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "mouseDown", [_object.action]))();
    _triggerAction(event) {
      const {
        action: actionVal,
        route,
        href
      } = this.args;
      if (actionVal || route || href?.length) {
        if (actionVal) {
          const {
            actionParam,
            forwardEvent
          } = this.args;
          if (typeof actionVal === "string") {
            (0, _deprecated.default)(...ACTION_AS_STRING_DEPRECATION_ARGS);
            if (this._target?.send) {
              this._target.send(actionVal, actionParam);
            } else {
              throw new Error("DButton could not find a target for the action. Use a closure action instead");
            }
          } else if (typeof actionVal === "object" && actionVal.value) {
            if (forwardEvent) {
              actionVal.value(actionParam, event);
            } else {
              actionVal.value(actionParam);
            }
          } else if (typeof actionVal === "function") {
            if (forwardEvent) {
              actionVal(actionParam, event);
            } else {
              actionVal(actionParam);
            }
          }
        } else if (route) {
          this.router.transitionTo(route);
        } else if (href?.length) {
          _url.default.routeTo(href);
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
    static #_8 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable no-pointer-down-event-binding }}
        <button
          {{! For legacy compatibility. Prefer passing class as attributes. }}
          class={{concatClass
            @class
            (if @isLoading "is-loading")
            (if this.btnLink "btn-link" "btn")
            (if this.noText "no-text")
            this.btnType
          }}
          {{! For legacy compatibility. Prefer passing these as html attributes. }}
          id={{@id}}
          form={{@form}}
          aria-controls={{@ariaControls}}
          aria-expanded={{this.computedAriaExpanded}}
          tabindex={{@tabindex}}
          type={{or @type "button"}}
          ...attributes
          disabled={{this.isDisabled}}
          title={{this.computedTitle}}
          aria-label={{this.computedAriaLabel}}
          {{on "keydown" this.keyDown}}
          {{on "click" this.click}}
          {{on "mousedown" this.mouseDown}}
        >
          {{#if @isLoading}}
            {{~icon "spinner" class="loading-icon"~}}
          {{else}}
            {{#if @icon}}
              {{#if @ariaHidden}}
                <span aria-hidden="true">
                  {{~icon @icon~}}
                </span>
              {{else}}
                {{~icon @icon~}}
              {{/if}}
            {{/if}}
          {{/if}}
    
          {{~#if this.computedLabel~}}
            <span class="d-button-label">
              {{~htmlSafe this.computedLabel~}}
              {{~#if @ellipsis~}}
                &hellip;
              {{~/if~}}
            </span>
          {{~else~}}
            &#8203;
            {{! Zero-width space character, so icon-only button height = regular button height }}
          {{~/if~}}
    
          {{yield}}
        </button>
      
    */
    {
      "id": "YWTp8XAK",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[11,\"button\"],[16,0,[28,[32,0],[[30,1],[52,[30,2],\"is-loading\"],[52,[30,0,[\"btnLink\"]],\"btn-link\",\"btn\"],[52,[30,0,[\"noText\"]],\"no-text\"],[30,0,[\"btnType\"]]],null]],[16,1,[30,3]],[16,\"form\",[30,4]],[16,\"aria-controls\",[30,5]],[16,\"aria-expanded\",[30,0,[\"computedAriaExpanded\"]]],[16,\"tabindex\",[30,6]],[16,4,[28,[32,1],[[30,7],\"button\"],null]],[17,8],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[16,\"title\",[30,0,[\"computedTitle\"]]],[16,\"aria-label\",[30,0,[\"computedAriaLabel\"]]],[4,[32,2],[\"keydown\",[30,0,[\"keyDown\"]]],null],[4,[32,2],[\"click\",[30,0,[\"click\"]]],null],[4,[32,2],[\"mousedown\",[30,0,[\"mouseDown\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,[28,[32,3],[\"spinner\"],[[\"class\"],[\"loading-icon\"]]]]],[]],[[[41,[30,9],[[[41,[30,10],[[[1,\"            \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,[28,[32,3],[[30,9]],null]],[13],[1,\"\\n\"]],[]],[[[1,[28,[32,3],[[30,9]],null]]],[]]]],[]],null]],[]]],[41,[30,0,[\"computedLabel\"]],[[[10,1],[14,0,\"d-button-label\"],[12],[1,[28,[32,4],[[30,0,[\"computedLabel\"]]],null]],[41,[30,11],[[[1,\"…\"]],[]],null],[13]],[]],[[[1,\"​\\n\"]],[]]],[18,12,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@class\",\"@isLoading\",\"@id\",\"@form\",\"@ariaControls\",\"@tabindex\",\"@type\",\"&attrs\",\"@icon\",\"@ariaHidden\",\"@ellipsis\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/Moderatori Plugin/discourse/components/button-edit-read-restriction.js",
      "scope": () => [_concatClass.default, _or.default, _modifier.on, _dIcon.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DButton;
});